<template>
  <b-modal
    id="unmatched-deposit-modal"
    title="ปรับยอด"
    hide-header
    hide-footer
    size="lg"
  >
    <b-overlay :show="loading">
      <form>
        <b-form-group
          :label="$t('fields.issued_date')"
          label-for="name"
          label-cols-sm="12"
          label-cols-lg="3"
        >
          <b-form-input
            id="name"
            :value="transaction.transactionDateTime | datetime"
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('bank_accounts.account_no')"
        >
          <b-form-input
            :value="`${transaction.fromAccountNumber} (${transaction.fromAccountName})`"
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('credits.type')"
        >
          <b-form-input
            :value="
              `(${transaction.transactionChannelCode}) ${transaction.transactionChannelDescription ||''}`
            "
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('fields.amount')"
        >
          <b-form-input
            :value="transaction.amount"
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('bank_accounts.channel')"
        >
          <b-form-input
            :value="
              `(${transaction.transactionCode}) ${transaction.transactionDescription ||''}`
            "
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('fields.remark')"
        >
          <b-form-input
            :value="transaction.transactionRemark"
            readonly
          />
        </b-form-group>
        <p>
          {{ $t('bank_accounts.detail') }}
        </p>
        <b-table-simple style="min-height: 0!important">
          <b-thead>
            <b-tr>
              <b-th style="width: 50px">
                #
              </b-th>
              <b-th>{{ $t('bank_accounts.bank') }}</b-th>
              <b-th>{{ $t('bank_accounts.account_name') }}</b-th>
              <b-th>{{ $t('bank_accounts.account_no') }}</b-th>
              <b-th>-</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(account, key) in depositUnmatchedDetail.users"
              :key="key"
            >
              <b-td>
                {{ key + 1 }}
              </b-td>
              <b-td>
                <BankIcon :bank-code="account.bankCode" />
                {{ $displayBankName(account.bankCode) }}
              </b-td>
              <b-td>
                <b-form-input
                  :value="account.bankAccountName"
                  readonly
                />
              </b-td>
              <b-td>
                <b-form-input
                  :value="account.bankAccountNumber"
                  readonly
                />
              </b-td>
              <b-td>
                <b-overlay :show="loading">
                  <b-button
                    variant="info"
                    size="sm"
                    :disabled="loading"
                    @click="onSubmit(account.username)"
                  >
                    {{ $t('credits.deposit') }}
                  </b-button>
                </b-overlay>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div class="row d-flex justify-content-center">
          <div class="col-6 ">
            <b-input-group class="mt-3">
              <b-form-input
                v-model="userCodeName"
                placeholder="เบอร์ยูสเซอร์"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-success"
                  :disabled="!userCodeName"
                  @click="onSubmit(userCodeName)"
                >
                  เติมเงิน
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <span class="text-small text-danger">**ถ้าหากไม่เจอรายการบัญชีให้ใส่เบอร์ยูสเซอร์ เพื่อเติมยอดนี้ให้ user</span>
          </div>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'UnmatchedDeposit',
  props: {
    txn: {
      type: Object,
      default: ()=> {},
    },
  },
  data() {
    return {
      selectedBank: null,
      userCodeName: '',
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.deposit.isAddingDepositUnmatched,
      isSuccess: (state) => state.deposit.isAddingDepositUnmatchedSuccess,
    }),
    ...mapGetters(['depositUnmatchedDetail']),
    transaction() {
      return this.depositUnmatchedDetail.transaction
    },
  },
  watch: {
    'txn.id'(val) {
      if(val){
        this.fetchData();
      }
    },
    isSuccess(val){
      if(val === true){
        this.closeModal()
      }
    },
    
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['fetchDepositUnmatchedById', 'addDepositUnmatched']),
    fetchData() {
      if(this.txn.id && this.$route.path === '/deposit'){
        this.fetchDepositUnmatchedById(this.txn.id)
      }
    },
    closeModal() {
      this.$bvModal.hide('unmatched-deposit-modal')
    },
    onSubmit(username) {
      this.addDepositUnmatched({
        id: this.txn.id,
        data: {
          username
        },
      })
    },
  },
}
</script>
